.calculator {
    background-color: #f3f3f3;
    padding: 4vh 3vw;
    padding-top: calc(4vh + 100px);

    height: 800px;
    width: 100%;

}

.calculator .calculator-box {
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 3px 29px -12px rgba(0, 0, 0, 0.69);
    -webkit-box-shadow: 0px 3px 29px -12px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 3px 29px -12px rgba(0, 0, 0, 0.69);
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.calculator-box .boxnav {

    box-shadow: 0px 6px 13px -7px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 6px 13px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 13px -7px rgba(0, 0, 0, 0.75);
}

.calculator-box .boxnav .row .col-lg-5 {
    border-top-right-radius: 24px;
    background-color: rgb(43, 43, 43);
    color: white;
    padding: 0;
}

.calculator-box .boxnav .row .col .div {
    cursor: pointer;
    margin: auto;
    padding-bottom: 8px;
    padding-top: 8px;
}

.calculator-box .boxnav .row .col .div h4 {
    font-size: 16px;
    font-weight: 400;
}

.underline {
    border-bottom: 2px solid black;
    font-weight: 500 !important;
}

.saving {
    margin-left: 16px !important;
}

.boxcontent .row {
    height: 100%;
}

.content-in h4 {
    font-size: 24px;
    font-weight: 400;
}

.content-in h5 {
    font-weight: 400;
    font-size: 16px;
}

.content-in input {
    border: 1px solid black;
    padding: 12px 16px;
    border-radius: 32px;
    width: 60%;
}

.content-in {
    height: 540px;
    display: grid;
}

.content-in .center {
    align-self: center;
}

.content-in .next {
    align-self: end;
}

.content-in .next {
    display: flex;
    justify-content: space-between;
}

#buttonnext, #buttonback {
    color: white;
    background-color: rgb(43, 43, 43);
    border: none;
    outline: none;
    padding: 16px;
    border-radius: 32px;
}

.radio-section input {
    display: none;

}

.radio-section label {
    display: flex;
    justify-content: space-between;

    width: 60%;
    background-color: #f3f3f3;
    transition: 0.4s;
    padding: 16px 16px;
}

.radio-section input[type="radio"]:checked+label {
    transition: 0.4s;
    background-color: rgb(43, 43, 43);
    color: white;

}

.boxcontent .col-lg-6:nth-child(1) {
    padding: 0;
}

.col-lg-6 img {
    height: 110%;
    width: 100%;
    object-fit: cover;
}

.paneltype label {
    justify-content: center !important;
}

.page4 h4 {
    margin-bottom: 16px;
}

.page4 .radio-section {
    margin-top: 16px;
}

.paneldesc {
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 16px 16px;
}

.paneldesc .ptitle h5 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.paneldesc .pdetail h5 {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 16px;

}

.paneldesc {
    margin-bottom: 16px;
}

.additions {
    display: flex;
    justify-content: flex-start;
}

.additions h4 {
    font-size: 16px;
}

.additions div {
    background-color: #f3f3f3;
    font-size: 12px;

    padding: 8px 16px;
    margin-left: 16px;
    border-radius: 8px;
}

.selectedaddition {
    color: white;
    background-color: rgb(43, 43, 43) !important;

}

.chargerad, .batteryad {
    cursor: pointer;
}
.page1 .next{
    justify-content: flex-end;
}
.page5 .calculation-result{
    background-color: rgb(43, 43, 43);
    height: 100%;
    color: white;
    padding: 32px 32px;
}
.page5 .calculation-result h6{
    font-size: 16px;
    font-weight: 400;
}

.household{
    background-color: rgb(86, 86, 86);
    padding: 24px 24px;
    border-radius: 16px;
    margin: 16px 0;
}
.household p{
    font-size: 14px;
    font-weight: 400;
}
.resultinv{
    margin-top: 16px;
    margin-bottom: 32px;
}
.resultinv h6{
    font-size: 24px !important;
}
.resultinv hr{
    color: white;
    border-color: white;
}
.resultinv .invoice div p{
    margin: 0;
}
.resultinv .hr1{
    border: 2px white solid;
}
.resultinv .hr2 {
    margin-bottom: 4px;
}
.resultinv .invoice div:nth-child(4) p{
    font-size: 18px;
    font-weight: 500;
}
.resultinv .invoice div:nth-child(4){
    margin-bottom: 64px !important;
}
.resultinv .hr3{
    margin: 4px;
}
.quoteform{
    padding: 32px 16px;
}
.quoteform h5{
    font-size: 24px;

}
.quoteform p{
    font-size: 14px;

}
.quoteform .form input{
    width: 100%;
    border-radius: 0;
    border: none;
    outline: none;
    border-bottom: 1px black solid;
    padding-left: 0;
    padding-bottom: 4px;
}
.quoteform .form input:active{
    outline: none;
}
.checkbox-c{
    text-align: left;
    display: flex;
    justify-content: left;
}
.checkbox-c input{
    margin-top: 4px !important;
    margin-right: 8px;
    width: fit-content !important;
}    

@media only screen and (max-width: 600px) {
    .calculator-box{
        overflow: scroll;
    }
    .calculator-box .boxnav{
        display: none;
    }
    .calculator{
        height: fit-content;
        padding-top: 160px;
    }
    .content-in{
        height: fit-content;
    }
    .center {
        padding: 108px 0;
    }
    .radio-section{
        width: 100%;
    }
    .radio-section label{
        width: 100%;
    }
    .mapimg{
        height: 200px !important;
    }
}
.justify-content-beetween{
    justify-content: space-between;
}
.mapimg{
    background-size: contain !important;
    height: 600px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

}
.boxnav h4 span{
    font-size: 12px;
    color: #6c6c6c;
}
.panel{
    padding: 100px 5vw;
}
.panel .item{
    margin: 100px 0;
}
.customercard{
    background-color: wheat;
    margin: 10px;
    padding: 16px;
    border-radius: 16px;
}
.imagelist img{
    width: 100%;
}
.imgcrd{
    border: 1px black solid;
    border-radius: 16px;
    padding: 16px;
    margin-top: 32px;
    background-color: #d8d8d8;
    display: flex;
    flex-direction: column;
}
.alignbottom{
    margin-top: auto;
}
.imgcrd input, .imgcrd textarea{
    width: 100%;
}
.errorbox{
    color: red;
}


@media only screen and (max-width: 600px) {
    .mapimg{
        height: 300px;
    }
    .mappbox{
        height: 300px;
    }
}

.galcard img{
    width: 100%;
}
.galcard div{
    display: flex;
    justify-content: space-between;
}
.galcard{
    padding: 16px;
}
.newgal{
    text-align: center;
    background-color: #d8d8d8;
}
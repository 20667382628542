.gallery{
    padding-top: 20vh;
}
.gallery .row .introcol{
    padding: 0;
    padding-top: 32px;
    border-top: black solid 2px;
}
.gallery .row .col-lg-10{
    padding: 0;
    padding-top: 32px;
}
.gallerysliders{
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: 32px;
    margin-bottom: 64px;
}
.galcards{
    margin-top: 48px;
}
.gallery .row .col-lg-10 h2{
    font-size: 48px;
    font-weight: 300;
}
.gallerysliders .gallerycard{
    padding: 8px 8px;
    border-top: 2px black solid;
}
.gallerycard .galleryimage img{
    width: 100%;
}
.gallerytitle{
    margin-top: 16px;
    margin-bottom: 64px;
}
.galleryinfo{
    margin-bottom: 8px;
}
.galleryinfo h4{
    border-top: 1px black solid;
    margin-bottom: 16px;
    padding-top: 8px;
}
.home {
    padding-top: 100px;
}

.calculate {
    height: 80vh;
    padding: 10vh 4vw;
    margin-bottom: 128px;
}

.calculate h3 {
    width: 60vw;
    font-size: 48px;
    color: white;
    font-weight: 300;
}

.calculate h4 {
    margin: 32px 0;
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.calculate input {
    padding: 16px 16px;
    border-radius: 32px;
    font-size: 16px;
    width: 432px;
    border: none;
    outline: none;
}

.calculate .inputbox {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 32px;
    width: 542px;
    padding: 4px;
    margin-bottom: 32px;
}

.calculate .inputbox .circle {
    background-color: rgb(255, 27, 115);
    border-radius: 50%;
    width: 64px;
    height: 64px;
    padding-top: 18px !important;
    padding-left: 16px;
    margin-left: auto;

}

#icontostyle {
    text-align: center !important;
}

.calculate button {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    box-shadow: none;
    padding: 16px;
    border-radius: 32px;
    color: white;
}
.whyus{
    margin-bottom: 128px;
}
.whyus .row{
    padding: 0 4vw;
}
.whyus h2{
    text-align: center;
    margin-bottom: 32px;
}
.whyus .col-lg-4{
    padding: 16px;
}
.whyus .col-lg-4 h5{
    font-weight: 600;
}
.contactus{
    padding: 0 4vw;
    text-align: center;
    margin-bottom: 128px;
}
.contactus h2{
    margin-top: 32px;
    margin-bottom: 32px;
    font-weight: 300;
}
.pages{
    margin: 128px 0;
    padding: 32px 4vw;
    background-color: #f3f3f3;

}
.pages h4{
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 400;
}
.pagelist{
    margin-top: 32px;
    color: black !important;
}
.pagelist Link{
    text-decoration: none;
}
.pagelist .row .col-lg-6{
    cursor: pointer;
    padding: 16px;
}
.pagelist .row .col-lg-6 .pageicon{
    margin-left: auto;
    padding-top: 12px;
}
.pagelist .row .col-lg-6:nth-child(1) {
    background-color: white !important;
}
.pagelist .row .col-lg-6:nth-child(1):hover {
    background-color: #f3f3f3 !important;
}
.pagelist .row .col-lg-6:hover{
    background-color: white !important;
}

@media only screen and (max-width: 600px) {
    .home .calculate{
        height: fit-content;
    }
    .home h3{
        font-size: 32px;
    }
    .home h4{
        font-size: 16px;
    }
    .home .inputbox{
        width: 100%;
    }
    .home input{
        width: 100%;
    }
}

.introcharger{
    padding: 4vh 0;
}
.introcharger .textsection{
    margin-bottom: 64px;
}
.introcharger .row .col-lg-6 img{
    width: 100%;
}
.introcharger .infocharger {
    padding: 32px 24px;
    background-color: #f3f3f3;

}
.introcharger .row .col-lg-6 .row {
    margin-bottom: 32px;
}
.infocharger h4{
    font-weight: 600;
}
.infocharger h6{
    font-weight: 400;
}
.chargerphotos{
    margin-top: 64px;
}
.chargerphotos img:nth-child(1){
    width: 51vw;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0;
}
.chargerphotos .col-lg-6{
    padding: 0;
    margin: 0;
}
.howtoorder{
    background-color: white!important;
}
.howtoorder:nth-child(1){
    letter-spacing: 2px;
}
.howtoorder h3{
    font-weight: 300;
    letter-spacing: normal;
}
.howtoorder img{
    margin-top: 32px;
    width: 100%;
    padding: 0 5vw;
}
.howtoorder .row{
    padding: 0 5vw;
}
.howtoorder .row .col-lg-3{
    margin-bottom: 32px;
}
.howtoorder .row .col-lg-3 h4{
    font-weight: 500;

}
.howtoorder .row .col-lg-3 h5{
    font-weight: 400;
    font-size: 18px;
}
.loadbalance{
    padding-left: 5vw;
    overflow: hidden;
}
.loadbalance .row .col-lg-6 img{
    width: 100%;
    
}
.loadbalance .row .col-lg-6:nth-child(1) {
    margin-top: 128px;
}
.loadbalance .row .col-lg-6 h3{
    margin: 16px 0;
    font-weight: 500;
}
.loadbalance .row .col-lg-6 h4{
    font-weight: 400;
}
footer .cprtext{
    background-color: rgba(0, 0, 0, 0.05);
}
.sociallinks a{
    text-decoration: none;
    color: white;
}
.sociallinks {
    display: flex;
    justify-content: center;

}
.sociallinks a{
    margin: 10px;
}
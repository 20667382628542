.parts{
    margin-top: 16vh;
}
.part{
    margin-bottom: 16vh;
}
.part:nth-child(1){
    width: 60%;
    margin: auto;
    font-weight: 400;
    margin-bottom: 16vh;

}
.part:nth-child(2){
    padding: 4vh 8vw;
    background-color: rgba(0, 0, 0, 0.06);
}
.part:nth-child(2) img{
    width: 100%;
    height: auto;
}
.part h4{
    margin-bottom: 4vh;
}
.part h3{
    font-weight: 400;
}
#corevalues{
    text-align: center;
    padding: 8vh 12vw;
}
#corevalues .row{
    padding: 8vh 0;
    text-align: left;
}

#aboutgallery .row img{
    width: 100%;
    height: auto;
}
#aboutgallery .row .col-lg-6{
    padding: 0;
}
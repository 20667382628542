.bsections {
    padding: 4vh 8vw;
}

.bsection:nth-child(1) {
    padding-top: 8vh;
    text-align: center;
}

.bsection:nth-child(1) .row {
    text-align: left;
    margin-top: 8vh;
    margin-bottom: 8vh;
}

.bsectiontwo .bsection:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.06);
    padding-bottom: 16vh;

}

.battery .percent{
    position: relative;
    width: 150px;
    height: 150px;
}
.battery .percent svg{
    position: relative;
    width: 150px;
    height: 150px;
}
.battery .percent svg circle{
    width: 150px;
    height: 150px;
    fill: none;
    stroke-width: 10;
    stroke: #000;
    transform: translate(5px, 5px);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}
.battery .percent svg circle:nth-child(1){
    stroke-dashoffset: 0;
    stroke: #f3f3f3;
}

.battery .percents .col-lg-3:nth-child(1) svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 30) / 100);
    stroke: #03a9f4;
}
.battery .percents .col-lg-3:nth-child(2) .percent svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 55) / 100);
    stroke: #03a9f4;
}
.battery .percents .col-lg-3:nth-child(3) .percent svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 70) / 100);
    stroke: #03a9f4;
}
.battery .percents .col-lg-3:nth-child(4) .percent svg circle:nth-child(2){
    stroke-dashoffset: calc(440 - (440 * 75) / 100);
    stroke: #03a9f4;
}
.battery .percent .number{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
}
.battery .percent .number p{
    font-size: 24px;
}
.battery .percent .number p span{
    font-size: 12px;
}
.battery .text{
    font-size: 16px;
    padding: 10px 0 0;
    color: #999;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
}
.percents{
    padding: 4vh 4vw;
}

.textsection{
    text-align: center;
}
.hybrid{
    padding: 4vh 4vw;
}
.hybrid .col-lg-5{
    background-color: #d9d9d9;
    padding: 2vh 2vw;
}
.hybrid .col-lg-5:nth-child(2) {
    margin-left: auto;
}
.compare .cbutton{
    text-align: center;

    
}

@media only screen and (max-width: 600px) {
    .battery .percent{
        margin-bottom: 160px;
        margin-left: auto;
        margin-right: auto;
    }
 }

 .faq{
     padding: 4vh 4vw;
 }
 .faq h4{
     font-weight: 400;
 }
 .faq h2{
     font-weight: 500;
 }
 .faq .quests .quest .row{
     margin-top: 16px;
     border-bottom: 1px solid black;
 }
 .faq .quests .quest .row h6{
     font-size: 20px;
     font-weight: 400;
     padding-left: 0;
     padding-bottom: 4px;
 }
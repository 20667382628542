@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html{
  overflow-x: hidden;

}
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.row{
  padding-left: 8px;
  padding-right: 8px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.sbgtype1{
  width: 100%;
  color: black;
  padding: 0px 4vw;
  padding-top: 16vh;
  padding-bottom: 48vh;
  margin-bottom: 4vh;
}
.sbgtype1 h3:nth-child(1){
  font-weight: 500;
  margin-bottom: 4vh;
}
.sbgtype1 h1{
  font-weight: 300;
}
.sbgtype2 {
  width: 100%;
  padding: 0px 4vw;
  padding-top: 16vh;
  padding-bottom: 48vh;
  margin-bottom: 4vh;
  text-align: center;
}
.sbgtype2 h4{
  font-weight: 400;
  margin-top: 64px;
}
.sbgtype2 h2{
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 32px;
}
.cbutton{
    border: none;
    outline: none;
    padding: 16px 16px;
    background-color: rgb(43, 43, 43);
    color: white;
    border-radius: 48px;
    transition: 0.3s;

}
.cbutton:hover{
    background-color: rgb(255, 27, 115);
    transition: 0.3s;
}

.contact .row {
    padding: 4vh 0;
}

.contact .row .col {
    padding-top: 4vh;
    border-top: 2px black solid;

}

.contact button {
    border: none;
    outline: none;
    padding: 16px 16px;
    background-color: rgb(43, 43, 43);
    color: white;
    color: white;
    border-radius: 48px;
    transition: 0.3s;

}

.contact button:hover {
    background-color: rgb(255, 27, 115);
    transition: 0.3s;
}

.contact {

    padding: 160px 4vw 100px 4vw;
}

.contactbox {
    background-color: rgb(230, 230, 230);
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
}

.contactbox .row {
    height: 100%;
    padding: 0;
}

.contactbox .row .col-lg-4 {
    padding: 24px;
}

.contactbox h2 {
    font-weight: 400;
}

.contactinfo {
    margin-top: 32px;
    padding-left: 16px;
}

.contactinfo a {
    text-decoration: none;
    color: black;
    font-size: 20px;
}

.contactinfo .d-flex {
    margin-top: 32px;
}

.contactinfo .d-flex a {
    padding-left: 16px;
}

.contactbox .col-lg-8 {
    padding: 24px;
    padding-right: 64px;
    background-color: rgb(246, 246, 246);

}

.col-lg-8 h3 {
    font-weight: 300;
}

.contactbox select, .contactbox input {
    padding: 8px;
    width: 100%;
    outline: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.75);
    background-color: transparent;
}

.contactbox .form .row {
    margin-top: 16px;
}

#accept {
    width: fit-content;
}

label {
    font-size: 14px;
}


@media only screen and (max-width: 600px) {
    .contact {
        padding: 300px 4vw 600px 4vw;

    }
}